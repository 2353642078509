<template>
  <v-container class="background fill-height d-block" fluid>
    <!--  Navigation  -->
    <u-project-top-nav :title="activeProjectName" extended>
      <template #extension>
        <u-stepper-progression-variant
          :step="stepper.step"
          :sub-step="stepper.subSteps"
          :subtitle="stepper.subtitle"
          :title="stepper.title"
          @click:prev="$router.push(stepper.prevLink)"
          @click:next="$router.push(stepper.nextLink)"
        />
      </template>
    </u-project-top-nav>

    <!--  Header  -->
    <v-container class="pb-0" fluid>
      <v-row justify="space-between">
        <v-col>
          <!--  Breadcrumbs  -->
          <u-breadcrumbs :items="breadcrumbItems" />
          <h1 class="secondary--text">Video Library</h1>
        </v-col>
        <v-spacer></v-spacer>
        <!-- Action Buttons -->
        <v-col
          v-if="projectCameras.length"
          align-self="end"
          class="d-flex justify-end"
        >
          <u-button
            class="rounded mr-2 text-capitalize font-weight-bold"
            color="gray-7"
            :to="`/setup/${activeProjectId}/video-upload`"
          >
            <v-icon class="mr-2" small>$queued</v-icon>
            Video Upload
          </u-button>
          <u-button
            class="rounded text-capitalize font-weight-bold"
            color="gray-7"
            :to="`/setup/${activeProjectId}/video-setup`"
          >
            <v-icon class="mr-2" small>fas fa-film</v-icon>
            Video Setup
          </u-button>
        </v-col>
      </v-row>
    </v-container>

    <!--  Content  -->
    <v-sheet
      v-if="projectCameras.length"
      :min-height="'65vh'"
      class="pa-4 ma-2 card-border"
      outlined
      rounded="lg"
    >
      <video-library-camera-videos-list :cameras="projectVideos" />
    </v-sheet>
    <!--  Empty Library View  -->
    <video-library-placeholder v-else />

    <!--  Action Buttons  -->
    <v-row class="px-4 my-4" justify="end">
      <u-button
        v-if="projectCameras.length"
        class="rounded mr-2 text-capitalize space-mono font-weight-bold"
        color="gray-7"
        large
        @click="addVideosFlag = true"
      >
        <v-icon class="mr-2" small>fas fa-plus</v-icon>
        Add New Videos
      </u-button>

      <u-button
        class="text-capitalize font-weight-bold secondary--text"
        color="primary"
        large
        :disabled="!projectCameras.length"
        @click="$router.push({ name: 'Camera Configuration' })"
      >
        Next
      </u-button>
    </v-row>

    <!--  Handles selection of videos and new camera creation  -->
    <add-videos v-model="addVideosFlag" auto-navigate />
  </v-container>
</template>

<script>
import { UBreadcrumbs, UButton } from "@/components/base";
import {
  UProjectTopNav,
  UStepperProgressionVariant,
} from "@/components/common";
import {
  VideoLibraryPlaceholder,
  AddVideos,
  VideoLibraryCameraVideosList,
} from "@/components";
import { mapActions, mapGetters, mapState } from "vuex";
import { PROJECT_SETUP_STEPPER } from "@/utils/navStepper.data";
import { PROJECT_SETUP_BREADCRUMBS } from "@/utils/breadcrumbs.data";

export default {
  components: {
    AddVideos,
    UStepperProgressionVariant,
    VideoLibraryPlaceholder,
    UProjectTopNav,
    UBreadcrumbs,
    UButton,
    VideoLibraryCameraVideosList,
  },
  computed: {
    ...mapState("Project", ["projectCameras", "projectVideos"]),
    ...mapGetters("Project", ["activeProjectName", "activeProjectId"]),
    stepper() {
      return this.projectCameras.length
        ? PROJECT_SETUP_STEPPER.videoLibrary
        : PROJECT_SETUP_STEPPER.videoLibraryPlaceholder;
    },
  },

  mounted() {
    // Make API call if no videos are in store
    !this.projectCameras.length && this.getProjectCameras(this.activeProjectId);
    !this.projectVideos.length && this.getProjectVideos();
  },
  data() {
    return {
      breadcrumbItems: PROJECT_SETUP_BREADCRUMBS,
      cameraDetails: [],
      addVideosFlag: false,
    };
  },
  methods: {
    ...mapActions("Project", ["getProjectCameras", "getProjectVideos"]),
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}
</style>
